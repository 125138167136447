module services {
    export module purchase {
        export class purchaseOrderService
            implements interfaces.purchase.IPurchaseOrderService {
            static $inject = [
                "$resource",
                "ENV",
                "$http",
                "generalService",
                "$timeout"
            ];

            constructor(
                private $resource: ng.resource.IResourceService,
                private ENV: interfaces.applicationcore.serverConfig,
                private $http: ng.IHttpService,
                public generalService: interfaces.applicationcore.IGeneralService,
                private $timeout: ng.ITimeoutService
            ) { }

            getPurchaseOrderList(): ng.resource.IResourceClass<
                interfaces.purchase.IPurchaseOrderDisplay
            > {
                return this.$resource<interfaces.purchase.IPurchaseOrderDisplay>(
                    this.ENV.DSP_URL + "Purchase/GetOrders",
                    {
                        connectSearch: "@connectSearch",
                        numberRecords: "@numberRecords",
                        pageNumber: "@pageNumber"
                    },
                    {
                        query: {
                            method: "GET",
                            isArray: true,
                            interceptor: {
                                response: config.appResourceDateInterceptor.parseResponseDates
                            },
                            params: {
                                numberRecords: 25,
                                pageNumber: 1
                            }
                        }
                    }
                );
            }

            getPurchaseOrderListExcel(params: any): ng.IPromise<void> {
                var pdfDownload = document.createElement("a");
                document.body.appendChild(pdfDownload);

                var url = this.ENV.DSP_URL + "Purchase/GetOrdersExcel?";

                var searchObject: interfaces.applicationcore.ISearchObject = {
                    filters: [],
                    sorts: []
                };
                searchObject = params.connectSearch;

                url += "connectSearch=" + btoa(JSON.stringify(searchObject));

                return this.$http({
                    method: "GET",
                    responseType: "arraybuffer",
                    url: url
                })
                    .then(response => {
                        var filename = response.headers()["x-filename"] || "download.xlsx";
                        var fileBlob = new Blob([response.data as BlobPart], {
                            type: "application/octet-stream"
                        });

                        if (navigator.appVersion.toString().indexOf(".NET") > 0) {
                            // for IE browser
                            window.navigator.msSaveBlob(fileBlob, filename);
                        } else {
                            // for other browsers
                            var fileURL = window.URL.createObjectURL(fileBlob);
                            pdfDownload.href = fileURL;
                            pdfDownload.download = filename;
                            pdfDownload.click();
                            pdfDownload.remove();
                        }

                        this.$timeout(() => {
                            this.generalService.displayMessage(
                                "Your download has started.",
                                Enum.EnumMessageType.Success
                            );
                        });
                    })
                    .catch(errorResponse => {
                        var fileBlob = new Blob([errorResponse.data], {
                            type: "application/json"
                        });
                        var fileURL = window.URL.createObjectURL(fileBlob);
                        const reader = new FileReader();

                        // This fires after the blob has been read/loaded.
                        reader.addEventListener("loadend", (e: ProgressEvent) => {
                            const text = e.target["result"];

                            this.$timeout(() => {
                                this.generalService.displayMessageHandler(
                                    <interfaces.applicationcore.IMessageHandler>JSON.parse(text)
                                );
                            });
                        });

                        // Start reading the blob as text.
                        reader.readAsText(fileBlob);
                    });
            }

            GetPurchaseOrderDetailSKUListExcel(poId: number): ng.IPromise<void> {
                var pdfDownload = document.createElement("a");
                document.body.appendChild(pdfDownload);

                var url =
                    this.ENV.DSP_URL +
                    "Purchase/GetPurchaseOrderDetailSKUListExcel?poId=" +
                    poId;

                return this.$http({
                    method: "GET",
                    responseType: "arraybuffer",
                    url: url
                })
                    .then(response => {
                        var filename = response.headers()["x-filename"] || "download.xlsx";
                        var fileBlob = new Blob([response.data as BlobPart], {
                            type: "application/octet-stream"
                        });

                        if (navigator.appVersion.toString().indexOf(".NET") > 0) {
                            // for IE browser
                            window.navigator.msSaveBlob(fileBlob, filename);
                        } else {
                            // for other browsers
                            var fileURL = window.URL.createObjectURL(fileBlob);
                            pdfDownload.href = fileURL;
                            pdfDownload.download = filename;
                            pdfDownload.click();
                            pdfDownload.remove();
                        }

                        this.$timeout(() => {
                            this.generalService.displayMessage(
                                "Your download has started.",
                                Enum.EnumMessageType.Success
                            );
                        });
                    })
                    .catch(errorResponse => {
                        var fileBlob = new Blob([errorResponse.data], {
                            type: "application/json"
                        });
                        var fileURL = window.URL.createObjectURL(fileBlob);
                        const reader = new FileReader();

                        // This fires after the blob has been read/loaded.
                        reader.addEventListener("loadend", (e: ProgressEvent) => {
                            const text = e.target["result"];

                            this.$timeout(() => {
                                this.generalService.displayMessageHandler(
                                    <interfaces.applicationcore.IMessageHandler>JSON.parse(text)
                                );
                            });
                        });

                        // Start reading the blob as text.
                        reader.readAsText(fileBlob);
                    });
            }

            //Get a Purchase Order By detail
            getPurchaseOrder(): ng.resource.IResourceClass<
                interfaces.purchase.IPurchaseOrder
            > {
                return this.$resource<interfaces.purchase.IPurchaseOrder>(
                    this.ENV.DSP_URL + "Purchase/GetPurchaseOrder",
                    {},
                    {
                        query: {
                            method: "GET",
                            interceptor: {
                                response: config.appResourceDateInterceptor.parseResponseDates
                            },
                            isArray: false
                        }
                    }
                );
            }

            //Saves PO
            savePurchaseOrder(): ng.resource.IResourceClass<
                interfaces.purchase.IPurchaseOrder
            > {
                var saveOrder: ng.resource.IActionDescriptor = {
                    method: "POST"
                };

                return this.$resource<interfaces.purchase.IPurchaseOrder>(
                    this.ENV.DSP_URL + "Purchase/Save",
                    {},
                    {
                        query: saveOrder
                    }
                );
            }

            generatePODIComments(): ng.resource.IResourceClass<
                interfaces.purchase.IPurchaseOrder
            > {
                return this.$resource<interfaces.purchase.IPurchaseOrder>(
                    this.ENV.DSP_URL + "Purchase/GeneratePODIComments",
                    {}
                );
            }

            refreshTariffAndOrigin(
                purchaseOrderId: number
            ): ng.resource.IResourceClass<
                interfaces.applicationcore.IMessageHandler
            > {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(
                    this.ENV.DSP_URL + "Purchase/RefreshTariffAndOrigin",
                    {
                        purchaseOrderId: purchaseOrderId
                    },
                    {
                        save: {
                            method: "POST"
                        }
                    }
                );
            }

            createNewVersion(): ng.resource.IResourceClass<
                interfaces.purchase.IPurchaseOrder
            > {
                return this.$resource<interfaces.purchase.IPurchaseOrder>(
                    this.ENV.DSP_URL + "Purchase/CreateNewVersion",
                    {},
                    {
                        save: {
                            method: "POST"
                        }
                    }
                );
            }

            recalculateExchangeRate(): ng.resource.IResourceClass<
                interfaces.purchase.IPurchaseOrder
            > {
                return this.$resource<interfaces.purchase.IPurchaseOrder>(
                    this.ENV.DSP_URL + "Purchase/RecalculateExchangeRate",
                    {},
                    {
                        save: {
                            method: "POST"
                        }
                    }
                );
            }

            changeStatus(): ng.resource.IResourceClass<
                interfaces.purchase.IPurchaseOrder
            > {
                return this.$resource<interfaces.purchase.IPurchaseOrder>(
                    this.ENV.DSP_URL + "Purchase/ChangeStatus",
                    {},
                    {
                        save: {
                            method: "POST"
                        }
                    }
                );
            }

        updateChildOrderCosting(): ng.resource.IResourceClass<
            interfaces.applicationcore.IMessageHandler
        > {
            return this.$resource<interfaces.applicationcore.IMessageHandler>(
                this.ENV.DSP_URL + "Purchase/UpdateChildOrderCosting",
                {
                    purchaseOrderId: "@purchaseOrderId",
                    LinkedPurchaseOrderId: "@LinkedPurchaseOrderId",
                    CopyCostingFromLinkedOrder: "@CopyCostingFromLinkedOrder"
                }
            );
        }

        copyCostingFromParentToChildOrderByLine(): ng.resource.IResourceClass<
        interfaces.applicationcore.IMessageHandler
    > {
        return this.$resource<interfaces.applicationcore.IMessageHandler>(
            this.ENV.DSP_URL + "Purchase/CopyCostingFromParentToChildOrderByLine",
            {
                sourceSKUAllocationId: "@sourceSKUAllocationId",
                destinationSKUAllocationId: "@destinationSKUAllocationId"
            }
        );
    }

            //Called by "Create New" button
            create(): ng.resource.IResourceClass<
                interfaces.applicationcore.IMessageHandler
            > {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(
                    this.ENV.DSP_URL + "Purchase/Create",
                    {
                        OwnerEntityId: "@OwnerEntityId",
                        TypeId: "@TypeId",
                        SupplierId: "@SupplierId",
                        POCounterId: "@POCounterId",
                        poId: "@poId",
                        CustomNumber: "@CustomNumber"
                    }
                );
            }

            //Called by "Create From Existing" button.
            createExisting(): ng.resource.IResourceClass<
                interfaces.applicationcore.IMessageHandler
            > {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(
                    this.ENV.DSP_URL + "Purchase/CreateExisting",
                    {
                        OwnerEntityId: "@OwnerEntityId",
                        TypeId: "@TypeId",
                        SupplierBranchId: "@SupplierBranchId",
                        POCounterId: "@POCounterId",
                        poId: "@poId",
                        CustomNumber: "@CustomNumber"
                    }
                );
            }

            //Called by "Create Child Order" button
            createChildOrder(): ng.resource.IResourceClass<
                interfaces.applicationcore.IMessageHandler
            > {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(
                    this.ENV.DSP_URL + "Purchase/CreateChildOrder",
                    {
                        OwnerEntityId: "@OwnerEntityId",
                        SupplierId: "@SupplierId",
                        poId: "@poId",
                        CustomNumber: "@CustomNumber",
                        CopyParentCosting: "@CopyParentCosting",
                        POCounterId: "@POCounterId"
                    }
                );
            }

            getDropdownList(): ng.resource.IResourceClass<
                interfaces.applicationcore.IDropdownModel
            > {
                return this.$resource<interfaces.applicationcore.IDropdownModel>(
                    this.ENV.DSP_URL + "Purchase/GetDropdownList",
                    {
                        OwnerEntityId: "@OwnerEntityId",
                        PaymentMethodIsLC: "@PaymentMethodIsLC"
                    },
                    {
                        query: {
                            method: "GET",
                            isArray: true
                        }
                    }
                );
            }

            GetListforChildOrders(): ng.resource.IResourceClass<
                interfaces.applicationcore.IDropdownModel
            > {
                return this.$resource<interfaces.applicationcore.IDropdownModel>(
                    this.ENV.DSP_URL + "Purchase/GetListforChildOrders",
                    {
                        OwnerEntityId: "@OwnerEntityId",
                        supplierId: "@supplierId"
                    },
                    {
                        query: {
                            method: "GET",
                            isArray: true
                        }
                    }
                );
            }

            getDefaults(
                entityId?: number,
                typeId?: number
            ): ng.resource.IResourceClass<
                interfaces.purchase.IPurchaseOrderDefaults
            > {
                return this.$resource<interfaces.purchase.IPurchaseOrderDefaults>(
                    this.ENV.DSP_URL + "Purchase/GetDefaults",
                    {
                        entityId: entityId,
                        typeId: typeId
                    }
                );
            }
        }
    }
    angular
        .module("app")
        .service("purchaseOrderService", services.purchase.purchaseOrderService);
}
